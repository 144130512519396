import '../styles/global.scss';
import './components/menu.js';
import './components/footer-accordion';
import './components/header-mob-accordion';
import './components/cookies';
import './components/bottom-navbar';

window.glblMessages = {
  "en": {
    "yesBtn": "Yes",
    "noBtn": "No",
    "adultContentMsg": "Are you 18 years old?",
    "nextArticle": "Read more",
  },
  "ru": {
    "yesBtn": "Да",
    "noBtn": "Нет",
    "adultContentMsg": "Вам уже исполнилось 18 лет?",
    "nextArticle": "Читать далее",
  },
  "uk": {
    "yesBtn": "Так",
    "noBtn": "Ні",
    "adultContentMsg": "Вам вже є 18 років?",
    "nextArticle": "Читати більше",
  }
}

function getDomain() {
  const hostnameArray = window.location.hostname.split('.')
  const numberOfSubdomains = hostnameArray.length - 2
  return hostnameArray.length === 2 ? window.location.hostname : hostnameArray.slice(numberOfSubdomains).join('.')
}

// window.addEventListener("beforeunload", function(e) {
//   document.cookie = `origin=${window.location.href}; path=/; domain=${getDomain()};`
// }); 

window.addEventListener("pagehide", function (e) {
  document.cookie = `origin=${window.location.href}; path=/; domain=${getDomain()};`
});

const seoText = document.querySelector('.seo-text');

if (seoText) {
  const btn = document.querySelector('.show-more-seo')
  if (btn) {
    btn.addEventListener('click', () => {
      seoText.classList.toggle('seo-text_full');
      const newTitle = btn.dataset.text;
      btn.dataset.text = btn.textContent;
      btn.textContent = newTitle;
    })
  }
}

// HANDLE HOT-LINE CLOSE
const hotLineEl = document.querySelector('.hot-line');
const hotLineElWrapper = document.querySelector('.hot-line-wrapper');
const hotLineElCloseBtn = document.querySelector('.hot-line__close');

hotLineElCloseBtn.addEventListener('click', () => {
  hotLineElWrapper.classList.add('hot-line-wrapper_closed');
  // localStorage.setItem('hotLineClosed', true);
});


// HANDLE HEADER SCROLL
const handleHeaderScroll = () => {
  let startScrollPos = 0;
  const hasWarElements = hotLineElWrapper.previousElementSibling.classList.contains('war-elements');
  if (hasWarElements && window.innerWidth > 1024) {
    startScrollPos = 32;
  } else if (window.innerWidth <= 1024) {
    startScrollPos = 52;
  }
  if (hasWarElements && window.innerWidth <= 1024) {
    startScrollPos = 84;
  }
  if (window.scrollY > startScrollPos) {
    hotLineEl.classList.add('hot-line_scrolled');
  } else {
    hotLineEl.classList.remove('hot-line_scrolled');
  }
}

const languagesListBtn = document.querySelector('.languages-list__btn');
const languagesList = languagesListBtn.nextElementSibling;
languagesListBtn.addEventListener('click', () => {
  languagesList.classList.toggle('hidden');
});
languagesList.addEventListener('click', (e) => {

  languagesList.classList.add('hidden');

});

document.body.addEventListener('click', (e) => {
  if (!e.target.closest('.languages-list')) {
    languagesList.classList.add('hidden');
  }
});

document.addEventListener('scroll', handleHeaderScroll);
handleHeaderScroll();