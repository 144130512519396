import Splide from '@splidejs/splide';
import EmblaCarousel from 'embla-carousel'

class BottomNavbar {
  constructor() {
    // DOM elements
    this.navbarBtnToShow = document.querySelector('.bottom-navbar-btn');
    this.navbarBtnToHide = document.querySelector('.bottom-navbar__close');
    this.bottomNavbar = document.querySelector('.bottom-navbar');
    this.bottomNavbarWrapper = document.querySelector('.bottom-navbar-wrapper');
    this.cookies = document.querySelector('.cookie');
    this.emblaNode = document.querySelector('.embla');
    this.nextBtn = document.querySelector('.arrow-next');

    // State variables
    this.isNavbarHidden = false;
    this.isCookieHidden = false;
    this.imagesLoaded = false;
    this.tweenFactor = 0;
    this.tweenNodes = [];
    this.TWEEN_FACTOR_BASE = 1;
    this.SETTLE_PIXEL_THRESHOLD = 50; // Added threshold for custom settle

    // Initialize only if we have the necessary elements
    if (this.emblaNode) {
      this.init();
    }
  }

  init() {
    this.setupNavbarButtons();
    this.setupLazyLoading();
    this.setupCookiesInteraction();
    this.initCarousel();
  }

  setupNavbarButtons() {
    if (!this.navbarBtnToShow) return;

    this.navbarBtnToShow.addEventListener('click', () => {
      this.bottomNavbar.classList.add('bottom-navbar_opened');
      this.navbarBtnToShow.classList.add('bottom-navbar-btn_hidden');
      this.isNavbarHidden = false;
      if (this.cookies && !document.cookie.includes('cookiecook') && !this.isCookieHidden) {
        this.bottomNavbar.classList.add('bottom-navbar_opened-with-cookies');
      }
    });

    this.navbarBtnToHide.addEventListener('click', () => {
      this.bottomNavbar.classList.remove('bottom-navbar_opened');
      this.bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies');
      this.navbarBtnToShow.classList.remove('bottom-navbar-btn_hidden');
      this.isNavbarHidden = true;
      if (this.cookies) {
        this.cookies.classList.remove('cookie_with-navbar');
      }
    });
  }

  setupLazyLoading() {
    if (!this.bottomNavbarWrapper) return;

    window.addEventListener('scroll', () => {
      if (this.imagesLoaded) return;
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 10) {
        this.bottomNavbarWrapper.querySelectorAll('img').forEach((img) => {
          img.classList.remove('lazy');
          img.src = img.dataset.src;
        });
        this.imagesLoaded = true;
        this.bottomNavbarWrapper.classList.add('bottom-navbar-wrapper_opened');
      }
    });
  }

  setupCookiesInteraction() {
    if (!this.cookies || !this.bottomNavbar) return;

    if (!this.cookies.classList.contains('cookie_hidden')) {
      this.bottomNavbar.classList.add('bottom-navbar_opened-with-cookies');
    }

    window.addEventListener('scroll', () => {
      if (document.cookie.includes('cookiecook')) return;
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 860) {
        this.bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies');
        this.navbarBtnToShow.classList.remove('bottom-navbar-btn_cookie');
        this.cookies.classList.add('cookie_hidden');
        if (!this.isNavbarHidden) {
          this.bottomNavbar.classList.remove('bottom-navbar_opened-with-cookies');
        }
        this.isCookieHidden = true;
      }
    });
  }

  initCarousel() {
    if (!this.emblaNode) return;

    const viewportNode = this.emblaNode.querySelector('.embla__viewport');
    if (!viewportNode) return;

    const OPTIONS = { loop: true, dragFree: true };
    this.emblaApi = EmblaCarousel(viewportNode, OPTIONS);

    // Setup all carousel features
    this.setupTweenScale();
    this.setupScrollButtons();
    this.setupDarkModeSwitcher();

    // Setup custom settle behavior
    this.emblaApi.on('scroll', this.fireCustomSettle.bind(this));

    // Setup cleanup on destroy
    this.emblaApi
      .on('destroy', this.cleanupTweenScale.bind(this))
      .on('destroy', this.cleanupScrollButtons.bind(this))
      .on('destroy', this.cleanupDarkModeSwitcher.bind(this));
  }

  // Helper method for range limiting
  numberWithinRange(number, min, max) {
    return Math.min(Math.max(number, min), max);
  }

  // Tween scale setup and methods
  setupTweenScale() {
    this.setTweenNodes();
    this.setTweenFactor();
    this.tweenScale();

    this.emblaApi
      .on('reInit', this.setTweenNodes.bind(this))
      .on('reInit', this.setTweenFactor.bind(this))
      .on('reInit', this.tweenScale.bind(this))
      .on('scroll', this.tweenScale.bind(this))
      .on('select', this.tweenScale.bind(this))
      .on('settle', this.tweenScale.bind(this))
      .on('slideFocus', this.tweenScale.bind(this));
  }

  setTweenNodes() {
    this.tweenNodes = this.emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('.embla__slide__number');
    });
  }

  setTweenFactor() {
    this.tweenFactor = this.TWEEN_FACTOR_BASE * this.emblaApi.scrollSnapList().length;
  }

  tweenScale(eventName) {
    const engine = this.emblaApi.internalEngine();
    const scrollProgress = this.emblaApi.scrollProgress();
    const slidesInView = this.emblaApi.slidesInView();
    const isScrollEvent = eventName === 'scroll';
    const currentSlide = this.emblaApi.selectedScrollSnap();

    // First remove all active classes
    this.emblaApi.slideNodes().forEach(slide => slide.classList.remove('is-active'));

    this.emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        const tweenValue = 1 - Math.abs(diffToTarget * this.tweenFactor);
        const translateY = this.numberWithinRange(tweenValue, 0, 1) * -32;
        // const opacity = this.numberWithinRange(tweenValue, 0, 1); // For background color opacity
        const tweenNode = this.tweenNodes[slideIndex];
        const slideNode = this.emblaApi.slideNodes()[slideIndex];

        // Apply active class when slide is close enough to center
        const isCloseToCenter = Math.abs(diffToTarget) < 0.2;

        if (isCloseToCenter && tweenValue > 0.6) {
          slideNode.classList.add('is-active');
        }

        // Apply translateY to the tweenNode
        if (tweenNode) {
          tweenNode.style.transform = `translateY(${translateY}px)`;
        }

        // Apply background color with dynamic opacity
        // slideNode.style.backgroundColor = `rgba(255, 117, 52, ${opacity})`;
        // slideNode.style.transition = 'background-color 0.3s ease-out';
      });
    });
  }

  cleanupTweenScale() {
    if (this.tweenNodes) {
      this.tweenNodes.forEach((node) => node && node.removeAttribute('style'));
    }

    if (this.emblaApi) {
      this.emblaApi.slideNodes().forEach(slide => {
        slide.classList.remove('is-active');
        slide.style.backgroundColor = '';
        slide.style.transition = '';
      });
    }
  }

  // Scroll buttons setup
  setupScrollButtons() {
    if (this.nextBtn) {
      this.scrollNextHandler = () => this.emblaApi.scrollNext();
      this.nextBtn.addEventListener('click', this.scrollNextHandler);
    }
  }

  cleanupScrollButtons() {
    if (this.nextBtn && this.scrollNextHandler) {
      this.nextBtn.removeEventListener('click', this.scrollNextHandler);
    }
  }

  // Dark mode switcher
  setupDarkModeSwitcher() {
    // Add click event listeners to all slides
    this.emblaApi.slideNodes().forEach(slideNode => {
      slideNode.addEventListener('click', () => {
        // Check if this is the dark mode switcher slide
        if (slideNode.classList.contains('navbar-dark-mode-switcher')) {
          const hasAttr = this.bottomNavbar.toggleAttribute('data-isDark');
          const imgs = this.bottomNavbar.querySelectorAll('img') || [];

          // Find the image and title elements
          const slideImg = slideNode.querySelector('.bottom-navbar__item-image');
          const slideTitle = slideImg ? slideImg.nextElementSibling : null;

          if (hasAttr) {
            // Switch to light mode
            if (slideImg) {
              slideImg.innerHTML = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4086 20.3767C17.7052 20.3767 20.3777 17.7043 20.3777 14.4076C20.3777 11.1109 17.7052 8.43848 14.4086 8.43848C11.1119 8.43848 8.43945 11.1109 8.43945 14.4076C8.43945 17.7043 11.1119 20.3767 14.4086 20.3767Z" fill="white" stroke="white" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0514 17.6038L24.7974 18.7472M18.6842 24.8154L17.5588 22.0604M11.2206 22.0424L10.0772 24.7884M6.75498 17.5498L4 18.6752M4.02701 10.0592L6.77299 11.2026M10.1312 4L11.2566 6.75498M18.7382 4.02701L17.5948 6.77299M24.8154 10.1402L22.0604 11.2656" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              `;
            }

            if (slideTitle && window.lightModeTrans) {
              slideTitle.innerHTML = window.lightModeTrans;
            }

            imgs.forEach((itm) => {
              if (itm.classList.contains('white')) {
                itm.classList.remove('hidden-sm');
              } else {
                itm.classList.add('hidden-sm');
              }
            });
          } else {
            // Switch to dark mode
            if (slideImg) {
              slideImg.innerHTML = `
<svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.884 4.85547C21.1783 6.72398 21.9379 8.93703 21.9379 11.3356C21.9379 17.8688 16.3107 23.1695 9.37516 23.1695C8.01064 23.1695 6.68826 22.9575 5.46436 22.5732C7.71522 25.7934 11.5699 27.9269 15.9731 27.9269C22.9086 27.9269 28.5358 22.6262 28.5358 16.093C28.5358 10.8453 24.9063 6.40593 19.884 4.85547Z" fill="#2d2d2d" stroke="#2d2d2d" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
</svg>    
              `;
            }

            if (slideTitle && window.darkModeTrans) {
              slideTitle.innerHTML = window.darkModeTrans;
            }

            imgs.forEach((itm) => {
              if (itm.classList.contains('black')) {
                itm.classList.remove('hidden-sm');
              } else {
                itm.classList.add('hidden-sm');
              }
            });
          }

          // Update cookies
          if (document.cookie.includes('navbarDarkMode=no')) {
            document.cookie = `navbarDarkMode=yes; path=/; domain=.${window.location.hostname};`;
          } else {
            document.cookie = `navbarDarkMode=no; path=/; domain=.${window.location.hostname};`;
          }
        }
      });
    });

    // Initialize dark mode from cookie if needed
    this.initDarkModeFromCookie();
  }

  initDarkModeFromCookie() {
    if (document.cookie.includes('navbarDarkMode=yes') && this.bottomNavbar) {
      this.bottomNavbar.setAttribute('data-isDark', '');
      const darkModeSwitcher = this.emblaApi.slideNodes().find(slide =>
        slide.classList.contains('navbar-dark-mode-switcher')
      );

      if (darkModeSwitcher) {
        const slideImg = darkModeSwitcher.querySelector('.bottom-navbar__item-image');
        const slideTitle = slideImg ? slideImg.nextElementSibling : null;
        const imgs = this.bottomNavbar.querySelectorAll('img') || [];

        if (slideImg) {
          slideImg.innerHTML = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4086 20.3767C17.7052 20.3767 20.3777 17.7043 20.3777 14.4076C20.3777 11.1109 17.7052 8.43848 14.4086 8.43848C11.1119 8.43848 8.43945 11.1109 8.43945 14.4076C8.43945 17.7043 11.1119 20.3767 14.4086 20.3767Z" fill="white" stroke="white" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0514 17.6038L24.7974 18.7472M18.6842 24.8154L17.5588 22.0604M11.2206 22.0424L10.0772 24.7884M6.75498 17.5498L4 18.6752M4.02701 10.0592L6.77299 11.2026M10.1312 4L11.2566 6.75498M18.7382 4.02701L17.5948 6.77299M24.8154 10.1402L22.0604 11.2656" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          `;
        }

        if (slideTitle && window.lightModeTrans) {
          slideTitle.innerHTML = window.lightModeTrans;
        }

        imgs.forEach((itm) => {
          if (itm.classList.contains('white')) {
            itm.classList.remove('hidden-sm');
          } else {
            itm.classList.add('hidden-sm');
          }
        });
      }
    }
  }

  cleanupDarkModeSwitcher() {
    // Clean up event listeners on destroy by replacing nodes
    if (this.emblaApi) {
      this.emblaApi.slideNodes().forEach(slideNode => {
        slideNode.replaceWith(slideNode.cloneNode(true));
      });
    }
  }

  // Added fireCustomSettle method similar to tv-hosts.js
  fireCustomSettle() {
    const { dragHandler, location, target } = this.emblaApi.internalEngine();
    if (dragHandler.pointerDown()) return;

    const displacement = target.get() - location.get();
    if (Math.abs(displacement) < this.SETTLE_PIXEL_THRESHOLD) {
      this.emblaApi.scrollTo(this.emblaApi.selectedScrollSnap());
    }
  }
}

// Initialize the bottom navbar when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
  new BottomNavbar();
});
